import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, skip, take, takeUntil } from 'rxjs/operators';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import { isNotNil, PlatformDefinitions, PlatformKey, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SelectTimeScaleFilterComponent } from ':shared/components/select-time-scale-filter/select-time-scale-filter.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { getRealDateByPeriod } from ':shared/helpers/get-real-date-by-period';
import { getSortedPlatformKeys } from ':shared/helpers/get-sorted-platform-keys';
import { KillSubscriptions } from ':shared/interfaces';
import {
    DatesAndPeriod,
    getNfcWithRestaurantDisplayName,
    MalouPeriod,
    MalouTimeScalePeriod,
    Nfc,
    Restaurant,
    RestaurantWithTooltipProperties,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import * as AggregatedStatisticsActions from '../store/aggregated-statistics.actions';
import { PlatformFilterPage } from '../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelector from '../store/aggregated-statistics.selectors';
import { selectPlatformsFilter } from '../store/aggregated-statistics.selectors';
import { AggregatedStatisticsFiltersContext } from './filters.context';
import { PlatformCacheService } from './platform-cache.service';

interface InitSelectedRestaurants {
    restaurantsWithRoiActivated: Restaurant[];
}

@Component({
    selector: 'app-statistics-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        GroupedDateFiltersComponent,
        SelectBaseComponent,
        SelectPlatformsComponent,
        SelectRestaurantsComponent,
        SelectTimeScaleFilterComponent,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
    ],
    providers: [PlatformCacheService],
})
@AutoUnsubscribeOnDestroy()
export class FiltersComponent implements OnInit, KillSubscriptions {
    page = input<PlatformFilterPage>();
    showPlatformsFilter = input<boolean>(true);
    showTotemsFilter = input<boolean>(false);
    totems = input<NfcWithRestaurantDto[]>([]);
    timeScaleMinAcceptedDate = input<Date | null>();

    readonly SvgIcon = SvgIcon;
    readonly PlatformFilterPage = PlatformFilterPage;
    readonly DEFAULT_PERIODS = [
        MalouPeriod.LAST_SEVEN_DAYS,
        MalouPeriod.LAST_THIRTY_DAYS,
        MalouPeriod.LAST_THREE_MONTHS,
        MalouPeriod.LAST_TWELVE_MONTHS,
    ];

    period: WritableSignal<MalouPeriod> = signal(MalouPeriod.DEFAULT);
    startDate: WritableSignal<Date | null> = signal(null);
    endDate: WritableSignal<Date | null> = signal(null);

    connectedPlatforms: PlatformKey[] = [];
    restaurants$: Observable<RestaurantWithTooltipProperties[]>;
    readonly currentUser$ = this._store.select(selectUserInfos);

    user: User;

    selectedRestaurants: WritableSignal<Restaurant[]> = signal([]);
    selectedRestaurantIds: Signal<string[]> = computed(() => this.selectedRestaurants().map(({ _id }) => _id));

    selectableTotems: Signal<NfcWithRestaurantDto[]> = computed(
        () => this.totems()?.filter((totem) => this.selectedRestaurantIds().includes(totem.restaurantId)) ?? []
    );

    readonly selectedTotems: WritableSignal<NfcWithRestaurantDto[]> = signal([]);

    readonly MalouPeriod = MalouPeriod;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    platformsFilterControl: FormControl<string[]> = new FormControl<string[]>([]) as FormControl<string[]>;
    restaurantsFilterControl: FormControl<Restaurant[]> = new FormControl<Restaurant[]>([]) as FormControl<Restaurant[]>;
    totemsFilterControl: FormControl<Nfc[]> = new FormControl<Nfc[]>([]) as FormControl<Nfc[]>;
    timeScaleFilterControl: FormControl<MalouTimeScalePeriod> = new FormControl<MalouTimeScalePeriod>(
        MalouTimeScalePeriod.LAST_SIX_MONTHS
    ) as FormControl<MalouTimeScalePeriod>;

    constructor(
        private readonly _store: Store,
        private readonly _platformCacheService: PlatformCacheService,
        private readonly _translateService: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly screenSizeService: ScreenSizeService,
        public readonly roiContext: RoiContext
    ) {}

    ngOnInit(): void {
        this.currentUser$.pipe(filter(isNotNil)).subscribe((user) => (this.user = user));
        this._initRestaurantsList();
        this._listenStoreUpdates(this.showPlatformsFilter());
    }

    chooseBoundaryDate(dates: DatesAndPeriod): void {
        this._store.dispatch(AggregatedStatisticsActions.editDates({ dates: dates }));
    }

    onPlatformsChange(platforms: PlatformKey[]): void {
        this._store.dispatch(AggregatedStatisticsActions.editPlatforms({ page: this.page(), platforms: platforms }));
    }

    onRestaurantsChange(restaurants: Restaurant[]): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                const restaurantsToSet = this._initRoiSelectedRestaurants(restaurants);
                this.selectedRestaurants.set(restaurantsToSet);
                this._store.dispatch(AggregatedStatisticsActions.editRoiRestaurants({ roiRestaurants: restaurantsToSet }));
                break;

            default:
                this._store.dispatch(AggregatedStatisticsActions.editRestaurants({ restaurants }));
                this.selectedRestaurants.set(restaurants);
                if (this.page() === PlatformFilterPage.BOOSTERS) {
                    const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                    this.selectedTotems.set(this.totems()?.filter((totem) => restaurantIds.includes(totem.restaurantId)) || []);
                    this._store.dispatch(
                        AggregatedStatisticsActions.editTotems({
                            totems: this.selectedTotems().map((totem) => Nfc.fromNfcDto(totem)),
                        })
                    );
                }
                break;
        }
    }

    onTotemsChange(totems: NfcWithRestaurantDto[]): void {
        this.selectedTotems.set(totems);
        this._store.dispatch(AggregatedStatisticsActions.editTotems({ totems: totems.map((totem) => Nfc.fromNfcDto(totem)) }));
    }

    onTimeScaleChange(timeScale: MalouTimeScalePeriod): void {
        this._store.dispatch(AggregatedStatisticsActions.editTimeScale({ data: timeScale }));
    }

    computePlatformKeys(restaurants: Restaurant[]): void {
        this._platformCacheService
            .getPlatformKeysForRestaurants(restaurants)
            .pipe(take(1))
            .subscribe((platformKeys: PlatformKey[]) => {
                this.connectedPlatforms = platformKeys.filter((platformKey) => {
                    if (this.page() === PlatformFilterPage.E_REPUTATION) {
                        return ![PlatformKey.INSTAGRAM, PlatformKey.MAPSTR].includes(platformKey);
                    }
                    if (this.page() === PlatformFilterPage.SOCIAL_NETWORKS) {
                        const keys: string[] = PlatformDefinitions.getPlatformKeysWithRSStats();
                        return keys.includes(platformKey);
                    }
                    return true;
                });
                this.connectedPlatforms = getSortedPlatformKeys(this.connectedPlatforms);
                this._store
                    .select(selectPlatformsFilter({ page: this.page() }))
                    .pipe(take(1))
                    .subscribe((platformsKeys: PlatformKey[]) => {
                        if (platformsKeys.length === 0) {
                            this._initPlatformsFilter(this.connectedPlatforms);
                        }
                    });
            });
    }

    compareByRestaurantId(restaurant: Restaurant): string {
        return restaurant._id;
    }

    compareByTotemId(totem: Nfc): string {
        return totem.id;
    }

    totemsSortBy = (a: NfcWithRestaurantDto, b: NfcWithRestaurantDto): number =>
        getNfcWithRestaurantDisplayName(a) > getNfcWithRestaurantDisplayName(b) ? 1 : -1;

    totemDisplayWith = (option: NfcWithRestaurantDto): string =>
        option.name ?? option.chipName ?? this._translateService.instant('enums.nfc_type.sticker');

    private _initPlatformsFilter(platformsKeys: PlatformKey[]): void {
        this.onPlatformsChange(platformsKeys);
    }

    private _listenStoreUpdates(listenToPlatforms: boolean): void {
        const dates$ = this._store.select(AggregatedStatisticsSelector.selectDatesFilter).pipe(takeUntil(this.killSubscriptions$));

        // First event: initialization
        dates$.pipe(take(1)).subscribe((dates) => {
            this.period.set(dates.period);
            this.startDate.set(getRealDateByPeriod(dates, 'startDate'));
            this.endDate.set(getRealDateByPeriod(dates, 'endDate'));
            if (dates.period !== MalouPeriod.CUSTOM) {
                this._store.dispatch(
                    AggregatedStatisticsActions.editDates({
                        dates: { period: this.period(), startDate: this.startDate(), endDate: this.endDate() },
                    })
                );
            }
        });
        // Next events
        dates$.pipe(skip(1)).subscribe((dates) => {
            this.period.set(dates.period);
            this.startDate.set(dates.startDate);
            this.endDate.set(dates.endDate);
        });

        this._initListenToUpdates(listenToPlatforms);

        if (listenToPlatforms) {
            this._store
                .select(AggregatedStatisticsSelector.selectPlatformsFilter({ page: this.page() }))
                .pipe(takeUntil(this.killSubscriptions$))
                .subscribe((platforms: PlatformKey[]) => {
                    this.platformsFilterControl.setValue(getSortedPlatformKeys([...platforms]));
                });
        }

        this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter).subscribe((timeScale: MalouTimeScalePeriod) => {
            this.timeScaleFilterControl.setValue(timeScale);
        });
    }

    private _initRestaurantsList(): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                this.restaurants$ = this._aggregatedStatisticsFiltersContext.roiRestaurants$.pipe(takeUntil(this.killSubscriptions$));
                break;
            default:
                this.restaurants$ = this._store
                    .select(selectOwnRestaurants)
                    .pipe(
                        map(
                            (restaurants) =>
                                restaurants
                                    .sort(sortRestaurantsByInternalNameThenName)
                                    .map((restaurant) => new RestaurantWithTooltipProperties(restaurant, false)) ?? []
                        )
                    );
                break;
        }
    }

    private _initListenToUpdates(listenToPlatforms: boolean): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                this._startRoiListener();
                break;
            default:
                this._startDefaultListener(listenToPlatforms);
                break;
        }
    }

    private _initRoiSelectedRestaurants(
        restaurants: Restaurant[],
        { restaurantsWithRoiActivated }: InitSelectedRestaurants = { restaurantsWithRoiActivated: [] }
    ): Restaurant[] {
        if (!restaurants.length) {
            return restaurantsWithRoiActivated.filter(
                (restaurant) => !restaurant.isBrandBusiness() && this.roiContext.isRestaurantRoiSettingsComplete(restaurant.id)
            );
        }
        if (!restaurantsWithRoiActivated?.length) {
            return restaurants.filter((restaurant) => this._shouldDisplayRestaurantInRoiFilter(restaurant));
        } else {
            return restaurants.filter(
                (restaurant) =>
                    restaurantsWithRoiActivated.some((restaurantWithRoiActivated) => restaurantWithRoiActivated._id === restaurant._id) &&
                    this._shouldDisplayRestaurantInRoiFilter(restaurant)
            );
        }
    }

    private _startRoiListener(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.restaurants$])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([restaurants, currentRestaurantsList]: [Restaurant[], Restaurant[]]) => {
                const restaurantsToSet = this._initRoiSelectedRestaurants(restaurants, {
                    restaurantsWithRoiActivated: currentRestaurantsList,
                });
                if (!restaurants.length && restaurantsToSet.length) {
                    this._store.dispatch(AggregatedStatisticsActions.editRoiRestaurants({ roiRestaurants: restaurantsToSet }));
                }
                this.restaurantsFilterControl.setValue(restaurantsToSet);
                this.selectedRestaurants.set(restaurantsToSet);
            });
    }

    private _startDefaultListener(listenToPlatforms: boolean): void {
        combineLatest([
            this._aggregatedStatisticsFiltersContext.selectedRestaurants$,
            this._store.select(AggregatedStatisticsSelector.selectTotemsFilter),
        ])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([restaurants, totems]: [Restaurant[], Nfc[]]) => {
                this.restaurantsFilterControl.setValue(restaurants);
                this.selectedRestaurants.set(restaurants);
                this.totemsFilterControl.setValue(totems);
                if (listenToPlatforms) {
                    void this.computePlatformKeys(restaurants);
                }
            });
    }

    private _shouldDisplayRestaurantInRoiFilter(restaurant: Restaurant): boolean {
        return (
            this.roiContext.isRestaurantRoiSettingsComplete(restaurant.id) &&
            !restaurant.isBrandBusiness() &&
            (this.user?.isAdmin() || restaurant.roiActivated)
        );
    }
}
