import { z } from 'zod';

import {
    FileFormat,
    MediaCategory,
    MediaType,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    PostType,
    SeoPostTopic,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { postValidator } from './validators.dto';

export const postsResizeAttachmentsValidator = z.object({
    postSource: z.nativeEnum(PostSource),
    postMedias: z.array(
        z.object({
            id: z.string().nullish(),
            restaurantId: z.string().nullable(),
            userId: z.string().nullish(),
            title: z.string().nullish(),
            description: z.string().nullish(),
            name: z.string().nullish(),
            category: z.nativeEnum(MediaCategory).nullable(),
            format: z.nativeEnum(FileFormat),
            type: z.nativeEnum(MediaType),
            urls: z.object({
                original: z.string(),
                small: z.string().optional(),
                igFit: z.string().optional(),
            }),
            sizes: z.object({
                original: z.number().optional(),
                small: z.number().optional(),
                igFit: z.number().optional(),
            }),
            postIds: z.array(z.string()).optional(),
            socialId: z.string().optional(),
            createdAt: z.string().optional(),
            updatedAt: z.string().optional(),
            isDeviceMedia: z.boolean().optional(),
            dimensions: z
                .object({
                    id: z.string().optional(),
                    original: z.object({
                        width: z.number(),
                        height: z.number(),
                    }),
                    small: z
                        .object({
                            width: z.number(),
                            height: z.number(),
                        })
                        .optional(),
                    igFit: z
                        .object({
                            width: z.number(),
                            height: z.number(),
                        })
                        .optional(),
                })
                .optional(),
            duration: z.number().nullish(),
            resizeMetadata: z.object({
                width: z.number(),
                height: z.number(),
                cropPosition: z.object({
                    top: z.number(),
                    left: z.number(),
                }),
                aspectRatio: z.number(),
            }),
            originalMediaId: z.string().optional(),
            folderId: z.string().nullish(),
        })
    ),
    options: z
        .object({
            shouldForceResizeToRecommendedSize: z.boolean(),
        })
        .optional(),
});

export type ResizePostAttachmentsDto = z.infer<typeof postsResizeAttachmentsValidator>;

export const createPostParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type CreatePostParamsDto = z.infer<typeof createPostParamsValidator>;

export const createPostBodyValidator = z
    .object({
        keys: z.array(z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK, PlatformKey.MAPSTR, PlatformKey.GMB])),
        post: z.object({
            text: z.string().optional(),
            createdAt: z.string().datetime(),
            plannedPublicationDate: z.string().datetime(),
            postType: z.nativeEnum(PostType),
            isStory: z.boolean(),
            source: z.nativeEnum(PostSource).optional(),
        }),
    })
    .transform((data) => ({
        ...data,
        post: {
            ...data.post,
            createdAt: new Date(data.post.createdAt),
            plannedPublicationDate: new Date(data.post.plannedPublicationDate),
        },
    }));
export type CreatePostBodyDto = z.infer<typeof createPostBodyValidator>;

export const duplicatePostBodyValidator = z
    .object({
        keys: z.array(z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK, PlatformKey.MAPSTR, PlatformKey.GMB])),
        draft: z.boolean(),
        duplicate_post_id: objectIdValidator,
        post: postValidator,
    })
    .transform((data) => ({
        ...data,
        duplicatePostId: data.duplicate_post_id,
        post: {
            published: data.draft ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING,
            ...Object.entries(data.post).reduce((a, [k, v]) => (v === null || v === undefined ? a : { ...a, [k]: v }), {}),
        },
    }));
export type DuplicatePostBodyValidator = z.infer<typeof duplicatePostBodyValidator>;

export const getTop3PostsInsightsBodyDtoValidator = z.object({
    platformKeys: z.array(z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK])),
    restaurantIds: z.array(objectIdValidator),
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
});
export type GetTop3PostsInsightsBodyDto = z.infer<typeof getTop3PostsInsightsBodyDtoValidator>;

// ------------------------------------------------------------------------------------------

export const createStoryParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type CreateStoryDto = z.infer<typeof createStoryParamsValidator>;

export const createStoryBodyValidator = z.object({
    malouStoryId: z.string().uuid(),
    keys: z.array(z.nativeEnum(PlatformKey)),
    attachmentId: z.string().optional(),
    plannedPublicationDate: z.string().datetime().optional(),
    duplicatedFromRestaurantId: z.string().optional(),
});

export type CreateStoryBodyDto = z.infer<typeof createStoryBodyValidator>;

export const prepareStoryPayloadValidator = z.object({
    malouStoryId: z.string().uuid(),
    keys: z.array(z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK])),
});

export type PrepareStoryPayloadDto = z.infer<typeof prepareStoryPayloadValidator>;

export const swapPlannedPublicationDatesPayloadValidator = z.object({
    /** the list of plannedPublicationDates to reassign */
    reassign: z.array(
        z.object({
            /** plannedPublicationDates will be read from this post */
            sourcePostId: objectIdValidator,

            /** The post that will be updated with the publication date of sourcePostId. */
            destinationPostId: objectIdValidator,
        })
    ),
});

export type SwapPlannedPublicationDatesPayloadDto = z.infer<typeof swapPlannedPublicationDatesPayloadValidator>;

export const cancelStoryPayloadValidator = z.object({
    malouStoryId: z.string().uuid(),
});

export type CancelStoryPayloadDto = z.infer<typeof cancelStoryPayloadValidator>;

export const updatePostPayloadValidator = z.object({
    keys: z.array(z.nativeEnum(PlatformKey)).optional(),
    language: z.string().optional(),
    text: z.string().optional(),
    plannedPublicationDate: z.string().datetime().optional(),
    platformId: objectIdValidator.optional(),
    attachments: z.array(objectIdValidator).optional(),
    attachmentsName: z.string().optional(),
    thumbnail: objectIdValidator.optional(),
    thumbnailOffsetTimeInMs: z.number().optional(),
    callToAction: z
        .object({
            actionType: z.string().optional(),
            url: z.string().optional(),
        })
        .optional(),
    postTopic: z.nativeEnum(SeoPostTopic).optional(),
    postType: z.nativeEnum(PostType).optional(),
    isReelDisplayedInFeed: z.boolean().optional(),
    event: z
        .object({
            title: z.string(),
            startDate: z.string().datetime(),
            endDate: z.string().datetime(),
        })
        .optional(),
    offer: z
        .object({
            couponCode: z.string().optional(),
            onlineUrl: z.string().optional(),
            termsConditions: z.string().optional(),
        })
        .optional(),
    location: z
        .object({
            id: z.string(),
            name: z.string(),
            link: z.string().optional(),
            location: z.object({
                city: z.string(),
                country: z.string(),
                latitude: z.number(),
                longitude: z.number(),
                street: z.string().optional(),
                zip: z.string().optional(),
            }),
        })
        .optional(),
    userTagsList: z
        .array(
            z.array(
                z.object({
                    username: z.string(),
                    x: z.number().optional(),
                    y: z.number().optional(),
                })
            )
        )
        .optional(),
    feedbackId: objectIdValidator.optional(),
    malouStoryId: z.string().uuid().optional(),
    title: z.string().optional(),
});

export type UpdatePostPayloadDto = z.infer<typeof updatePostPayloadValidator>;

export const updatePostParamsValidator = z
    .object({
        post_id: objectIdValidator,
    })
    .transform((data) => ({
        postId: data.post_id,
    }));
export type UpdatePostParamsDto = z.infer<typeof updatePostParamsValidator>;
