import { z } from 'zod';

import { ApplicationLanguage, CustomerNaming, ReplyTone } from '@malou-io/package-utils';

export const restaurantAiSettingsValidator = z.object({
    restaurantName: z.string(),
    restaurantId: z.string(),
    replyTone: z.nativeEnum(ReplyTone),
    defaultLanguageResponse: z.nativeEnum(ApplicationLanguage),
    customerNaming: z.nativeEnum(CustomerNaming),
    restaurantKeywordIds: z.array(z.string()),
    forbiddenWords: z.array(z.string()),
    catchphrase: z.string().optional(),
    shouldTranslateCatchphrase: z.boolean(),
    signatures: z.array(z.string()).optional(),
    shouldTranslateSignature: z.boolean(),
    sourceLanguage: z.nativeEnum(ApplicationLanguage).nullish(),
});

export interface RestaurantAiSettingsDto {
    restaurantId: string;
    restaurantName: string;
    replyTone: ReplyTone;
    defaultLanguageResponse: ApplicationLanguage;
    customerNaming: CustomerNaming;
    restaurantKeywordIds: string[];
    forbiddenWords: string[];
    catchphrase?: string;
    duplicatedFromRestaurantId?: string;
    shouldTranslateCatchphrase: boolean;
    catchphraseTranslationId?: string;
    signatures?: string[];
    shouldTranslateSignature: boolean;
    signatureTranslationIds?: string[];
}
