<!-- ⚠️ we are experimenting a new version of this component: see statistics-seo-keywords-v3.component.ts -->

<div class="flex flex-col">
    <div class="flex h-12.5 flex-col md:h-fit">
        <div class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.keywords.keywords' | translate }}</div>
        <div class="malou-text-10--regular malou-color-text-2 line-clamp-1 italic md:line-clamp-none">
            ({{ 'statistics.seo.keywords.only_malou_content' | translate }})
        </div>
    </div>
    @if (isLoading()) {
        <app-skeleton skeletonClass="!h-20 secondary-bg"></app-skeleton>
    } @else {
        @if (!httpError) {
            <div class="flex gap-6 lg:gap-2.5">
                <ng-container
                    [ngTemplateOutlet]="cardTemplate"
                    [ngTemplateOutletContext]="{
                        tooltip:
                            keywordsInfos.count.current > 0
                                ? ('statistics.seo.keywords.content_created_tooltip'
                                  | translate
                                      : {
                                            reviews: keywordsInfos.count.currentDetails.reviews,
                                            posts: keywordsInfos.count.currentDetails.posts,
                                        })
                                : ('statistics.seo.keywords.no_content_including_keywords' | translate),
                        currentValue: keywordsInfos.count.current | shortNumber,
                        currentValueRaw: keywordsInfos.count.current,
                        diffValue: keywordsInfos.count.diff,
                        diffDisplayedValue: keywordsInfos.count.diff | shortNumber: { content: '%', shouldDisplayMinusSign: false },
                        title: 'statistics.seo.keywords.content_created' | translate,
                        icon: 'image',
                    }"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="cardTemplate"
                    [ngTemplateOutletContext]="{
                        tooltip: 'statistics.seo.keywords.score_average_tooltip' | translate,
                        currentValue: keywordsInfos.average.current | shortNumber,
                        currentValueRaw: keywordsInfos.average.current,
                        diffValue: keywordsInfos.average.diff,
                        diffDisplayedValue: keywordsInfos.average.diff | shortNumber: { content: 'pts', shouldDisplayMinusSign: false },
                        title: 'statistics.seo.keywords.score_average' | translate,
                        icon: 'bookmark',
                    }"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="cardTemplate"
                    [ngTemplateOutletContext]="{
                        tooltip: 'statistics.seo.keywords.in_top_20_tooltip' | translate,
                        currentValue: keywordsInfos.top20.current | shortNumber,
                        currentValueRaw: keywordsInfos.top20.current,
                        diffValue: keywordsInfos.top20.diff,
                        diffDisplayedValue: keywordsInfos.top20.diff | shortNumber: { shouldDisplayMinusSign: false },
                        title: 'statistics.seo.keywords.in_top_20' | translate,
                        icon: 'medal',
                    }"></ng-container>
            </div>
            @if (dataSource.data.length > 0) {
                <ng-container *ngTemplateOutlet="screenSizeService.isPhoneScreen ? mobileList : desktopTable"></ng-container>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
            </div>
        }
    }
</div>

<ng-template let-icon="icon" #gradientCircleTemplate>
    <div class="malou-background-gradient-5 flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full">
        <mat-icon class="malou-color-white !h-4 !w-4" [svgIcon]="icon"></mat-icon>
    </div>
</ng-template>

<ng-template
    let-tooltip="tooltip"
    let-currentValue="currentValue"
    let-currentValueRaw="currentValueRaw"
    let-diffValue="diffValue"
    let-diffDisplayedValue="diffDisplayedValue"
    let-title="title"
    let-icon="icon"
    #cardTemplate>
    <div
        class="malou-simple-card flex h-20 flex-1 items-center justify-between gap-4 px-8 py-2 xl:h-fit xl:flex-row xl:items-center xl:justify-start">
        <div class="flex items-end xl:items-center">
            <ng-container [ngTemplateOutlet]="gradientCircleTemplate" [ngTemplateOutletContext]="{ icon }"></ng-container>
            <div class="ml-4 flex items-baseline xl:grow xl:flex-col">
                <div class="flex items-center xl:justify-between" [matTooltip]="tooltip">
                    <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none">{{ currentValue }}</span>
                </div>
                <span class="malou-text-12--medium malou-color-text-2 mb-1 whitespace-nowrap">{{ title }}</span>
            </div>
        </div>
        @if (currentValueRaw) {
            <app-number-evolution [value]="diffValue" [displayedValue]="diffDisplayedValue"></app-number-evolution>
        }
    </div>
</ng-template>

<ng-template #desktopTable>
    <mat-table
        class="malou-mat-table mt-5"
        matSort
        [dataSource]="dataSource"
        [matSortActive]="defaultSort.active"
        [matSortDirection]="defaultSort.direction"
        (matSortChange)="onSortChange($event)"
        #table="matTable">
        <ng-container matColumnDef="keyword">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'keywords.keywords' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="malou-text-13--semibold truncate !text-malou-color-text-1">
                <div class="truncate" [matTooltip]="row.keyword">
                    {{ row.keyword }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'keywords.validation.lang' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table; let i = index">
                <img
                    class="ml-4 !h-4 !w-4"
                    [matTooltip]="getPrettyLang | applyPure: row.language"
                    [lazyLoad]="row.language | flagPathResolver"
                    [alt]="getPrettyLang | applyPure: row.language" />
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="volume">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <span class="flex items-center gap-x-1">
                    <span>
                        {{ 'keywords.popularity' | translate }}
                    </span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex cursor-pointer items-center gap-x-2">
                    <app-keywords-popularity [popularity]="row.popularity"></app-keywords-popularity>
                    @if (isMalouAdmin) {
                        <mat-icon
                            class="!h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.EDIT"
                            [ngClass]="{ 'existing-volume': !!row.volumeFromAPI }"
                            (click)="openCustomVolumeModal(row)">
                        </mat-icon>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef>
                <span class="flex gap-x-1">
                    <mat-icon
                        class="pdf-hidden !h-4 !w-4"
                        matTooltip="{{ 'keywords.local_search' | translate }}"
                        color="primary"
                        [svgIcon]="SvgIcon.INFO"></mat-icon>
                    <span>{{ 'keywords.maps_position' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                @if (row.error && !(isRowCurrentPositionValid | applyPure: row) && !(isErrorFixable | applyPure: row?.errorData)) {
                    <div class="flex items-center">
                        <span class="mr-2">
                            {{ 'keywords.no_info' | translate }}
                        </span>
                        <mat-icon
                            class="malou-text--medium pdf-hidden !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.INFO"
                            [matTooltip]="getErrorDetail | applyPure: row.errorData"></mat-icon>
                    </div>
                } @else {
                    @if (!row.currentPosition) {
                        <div
                            class="custom-tooltip"
                            [matTooltip]="
                                (canRefresh | applyPure: row) && doesPeriodEndToday()
                                    ? ''
                                    : ((doesPeriodEndToday() ? 'keywords.no_results_try_24_hours' : 'keywords.no_results_try_other_period')
                                      | translate)
                            ">
                            @if (!row.isWaiting) {
                                <button
                                    class="malou-btn-flat disabled:cursor-default"
                                    mat-flat-button
                                    [disabled]="!doesPeriodEndToday() || !(canRefresh | applyPure: row) || row.isWaiting || refreshStarted"
                                    (click)="refreshKeyword(row)">
                                    {{ 'keywords.no_info' | translate }}
                                    @if (doesPeriodEndToday()) {
                                        <mat-icon class="pdf-hidden" color="primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
                                    }
                                </button>
                            }
                        </div>
                    }
                    @if (row.currentPosition) {
                        <ng-container
                            [ngTemplateOutlet]="positionCell"
                            [ngTemplateOutletContext]="{
                                positionDetails: getEmojiAndTextFromPosition | applyPure: row.currentPosition,
                                errorData: row.errorData,
                            }"></ng-container>
                    }
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="evolution">
            <mat-header-cell *matHeaderCellDef>
                <span class="flex items-center gap-x-1">
                    <span>{{ 'keywords.evolution_cell' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let row; table: table"
                mat-cell
                [matTooltip]="'keywords.evolution_tooltip' | translate: { text: row.keyword }"
                (click)="openKeywordEvolution(row.keyword)">
                <div class="h-12 w-full justify-center">
                    @if (row.positions && row.positions.length) {
                        <app-keyword-evolution-mini [positions]="row.positions"></app-keyword-evolution-mini>
                    } @else {
                        <button class="malou-btn-flat !p-0">
                            {{ 'common.see' | translate }}
                        </button>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="competitors">
            <mat-header-cell *matHeaderCellDef>
                <span class="flex w-full items-center justify-center gap-x-1">
                    <span>{{ 'keywords.competitors_col' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="justify-center">
                <button
                    class="malou-btn-flat !p-0"
                    [disabled]="row.isLoading || !row.currentPosition?.rank"
                    (click)="openCompetitorsList(row.keyword, row.ranking, row.currentPosition?.rank)">
                    {{ 'common.see' | translate }}
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #mobileList>
    <div class="malou-card !m-0 !my-5 max-h-12 !flex-row gap-x-3 !py-4">
        <div class="flex w-full items-center gap-x-2" [matMenuTriggerFor]="sortMenu">
            <mat-icon
                class="!h-4 !w-4"
                color="primary"
                [svgIcon]="sortDirection === 1 ? 'arrow-up' : 'arrow-down'"
                (click)="$event.stopPropagation(); changeSortOrder()"></mat-icon>
            <span class="malou-text-13--medium">{{ 'common.sort' | translate }} : </span>
            <span class="malou-text-13--bold max-w-[50%] truncate">{{ selectedSortOption.text }}</span>
            <mat-icon class="ml-auto !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </div>

        <mat-menu class="malou-mat-menu malou-box-shadow !mt-3.5 !w-[100vw] !rounded-xl" #sortMenu="matMenu">
            @for (sort of sortOptions; track sort) {
                <button class="custom-menu-item" mat-menu-item (click)="sortKeywords(sort)">
                    <span class="malou-text-13--regular !text-malou-color-text-1">{{ sort.text }}</span>
                </button>
            }
        </mat-menu>
    </div>

    <div class="mb-12 flex w-full flex-col gap-y-2">
        @for (row of dataSource.data; track trackByIdFn($index, row)) {
            <div class="malou-card !m-0 !flex-none gap-y-5 !p-5">
                <span class="malou-text-13--semibold truncate">{{ row.keyword }}</span>
                <div>
                    <button
                        class="malou-btn-flat !p-0"
                        [disabled]="row.isLoading"
                        (click)="openCompetitorsList(row.keyword, row.ranking, row.currentPosition?.rank)">
                        {{ 'keywords.see_competitors' | translate }}
                    </button>
                </div>
                @if (row.currentPosition) {
                    <ng-container
                        [ngTemplateOutlet]="positionCell"
                        [ngTemplateOutletContext]="{
                            positionDetails: getEmojiAndTextFromPosition | applyPure: row.currentPosition,
                            errorData: row.errorData,
                        }"></ng-container>
                }
                <div (click)="openKeywordEvolution(row.keyword)">
                    <span class="flex items-center gap-x-1">
                        <span class="malou-text-13--regular">{{ 'keywords.evolution_cell' | translate }}</span>
                    </span>
                    @if (row.positions) {
                        <app-keyword-evolution-mini [positions]="row.positions"></app-keyword-evolution-mini>
                    }
                </div>
                <div class="flex items-center gap-x-3">
                    <span class="malou-text-13--regular">
                        {{ 'keywords.popularity' | translate }}
                    </span>
                    <app-keywords-popularity [popularity]="row.popularity"></app-keywords-popularity>
                    @if (isMalouAdmin) {
                        <mat-icon
                            class="!h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.EDIT"
                            [ngClass]="{ 'existing-volume': !!row.volumeFromAPI }"
                            (click)="openCustomVolumeModal(row)">
                        </mat-icon>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-positionDetails="positionDetails" let-errorData="errorData" #positionCell>
    <div class="flex items-center gap-x-2">
        <div class="emoji-container flex items-center gap-x-2 text-lg">
            @if (positionDetails?.emojiSrc) {
                <div class="w-[22px]">
                    <img class="w-full" [src]="positionDetails.emojiSrc" />
                </div>
            }
            @if (errorData === 'results_too_far') {
                <mat-icon class="malou-color-warning" [matTooltip]="'keywords.results_too_far_warning' | translate"> warning </mat-icon>
            }
        </div>
        <div class="position">
            <div class="malou-text-13--semibold">{{ positionDetails.title }}</div>
            <div class="malou-text-10--regular">{{ positionDetails.caption }}</div>
        </div>
    </div>
</ng-template>
