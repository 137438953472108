import { z } from 'zod';

import { MalouPeriod, MalouTimeScalePeriod, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const userFiltersByUserIdParamsValidator = z
    .object({
        user_id: objectIdValidator,
    })
    .transform((data) => ({
        userId: data.user_id,
    }));

export type UserFiltersByUserIdParamsDto = z.infer<typeof userFiltersByUserIdParamsValidator>;

// --------------------------------------------

export const aggregatedStatisticsFiltersBodyValidator = z.object({
    dates: z.object({
        startDate: z.string().datetime().nullable(),
        endDate: z.string().datetime().nullable(),
        period: z.nativeEnum(MalouPeriod),
    }),
    platforms: z.object({
        [PlatformFilterPage.BOOSTERS]: z.array(z.string()),
        [PlatformFilterPage.E_REPUTATION]: z.array(z.string()),
        [PlatformFilterPage.SEO]: z.array(z.string()),
        [PlatformFilterPage.SOCIAL_NETWORKS]: z.array(z.string()),
    }),
    restaurantsIds: z.array(objectIdValidator),
    roiRestaurantsIds: z.array(z.string()),
    totemsIds: z.array(z.string()),
    timeScale: z.nativeEnum(MalouTimeScalePeriod),
});

export type AggregatedStatisticsFiltersBodyDto = z.infer<typeof aggregatedStatisticsFiltersBodyValidator>;

// --------------------------------------------

export const aggregatedReviewsFiltersBodyValidator = z.object({
    filters: z.object({
        period: z.nativeEnum(MalouPeriod),
        platforms: z.array(z.nativeEnum(PlatformKey)),
        text: z.string(),
        ratings: z.array(z.number()),
        answered: z.boolean(),
        notAnswered: z.boolean(),
        pending: z.boolean(),
        notAnswerable: z.boolean(),
        showPrivate: z.boolean(),
        withText: z.boolean(),
        withoutText: z.boolean(),
        archived: z.boolean(),
        unarchived: z.boolean(),
    }),
    restaurantsIds: z.array(objectIdValidator),
});

export type AggregatedReviewsFiltersBodyDto = z.infer<typeof aggregatedReviewsFiltersBodyValidator>;
