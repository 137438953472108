import { Clipboard } from '@angular/cdk/clipboard';
import { Component, computed, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationType } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-action-modal.component.html',
    styleUrls: ['./confirm-action-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
})
export class ConfirmActionModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    illustration = Illustration;

    readonly actionModalContent = {
        [NotificationType.SPECIAL_HOUR]: {
            title: `notification-center.confirm_action_modal.${NotificationType.SPECIAL_HOUR}.title`,
            text: `notification-center.confirm_action_modal.${NotificationType.SPECIAL_HOUR}.text`,
            confirm: `notification-center.confirm_action_modal.${NotificationType.SPECIAL_HOUR}.confirm`,
            cancel: `notification-center.confirm_action_modal.${NotificationType.SPECIAL_HOUR}.cancel`,
        },
    };

    getContent(key: 'title' | 'text' | 'confirm' | 'cancel'): string {
        return this.actionModalContent[this.data.notificationType]?.[key] || '';
    }

    readonly getTitle = computed(() => this.getContent('title'));
    readonly getText = computed(() => this.getContent('text'));
    readonly getConfirmAction = computed(() => this.getContent('confirm'));
    readonly getCancelAction = computed(() => this.getContent('cancel'));

    constructor(
        private readonly _dialogRef: MatDialogRef<ConfirmActionModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            notificationType: NotificationType;
        },
        public readonly _clipboard: Clipboard
    ) {}

    ngOnInit(): void {}

    close(isConfirmed: boolean): void {
        this._dialogRef.close({
            isConfirmed,
        });
    }
}
