import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, isFinite, mean, sum, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AggregationTimeScale, isNotNil, MalouMetric, PlatformKey, PostType } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { InsightsService } from ':modules/statistics/insights.service';
import {
    EngagementChartComponent,
    EngagementData,
    SplittedEngagementData,
} from ':modules/statistics/social-networks/engagement/engagement-chart/engagement-chart.component';
import { FollowersByDayObject } from ':modules/statistics/social-networks/posts-insights-table/posts-insights-table.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    getClosestValueFromDate,
    getDaysFromCurrentRange,
    getMonthsFromPeriod,
    getWeeksFromCurrentRange,
    isDateSetOrGenericPeriod,
    isInDayList,
    isSameDay,
    Month,
    WeekRange,
} from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import {
    DatesAndPeriod,
    getInsightsErrorText,
    InsightsByPlatform,
    PostsWithInsightsByPlatforms,
    PostWithInsights,
    Restaurant,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

export interface CurrentAndDiffInsights {
    current: number | null;
    diff: number | null;
}

const DEFAULT_SPLITTED_DATA: SplittedEngagementData = {
    facebookData: [],
    instagramData: [],
    total: [],
};

@Component({
    selector: 'app-engagement',
    templateUrl: './engagement.component.html',
    styleUrls: ['./engagement.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        EngagementChartComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        NumberEvolutionComponent,
        ShortNumberPipe,
        EnumTranslatePipe,
        LowerCasePipe,
        ApplyPurePipe,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class EngagementComponent implements OnInit, KillSubscriptions {
    @Input() showViewByTextInsteadOfSelector = false;
    @Input() viewBy?: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() readonly viewByChange: EventEmitter<ViewBy> = new EventEmitter<ViewBy>();
    @Output() readonly hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelector.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly totalEngagementRate: Signal<CurrentAndDiffInsights> = computed(() => {
        const current = this._currentTotalEngagementRate();
        const previous = this._previousTotalEngagementRate();
        return {
            current: current,
            diff: current && previous ? current - previous : null,
        };
    });

    readonly totalImpressions: Signal<CurrentAndDiffInsights> = computed(() => {
        const current = this._currentTotalImpressions();
        const previous = this._previousTotalImpressions();
        return {
            current: current,
            diff: current && previous ? current - previous : null,
        };
    });

    httpError: WritableSignal<string | null> = signal(null);
    isLoading = signal(true);
    insightsError: WritableSignal<string | null> = signal(null);
    readonly ViewBy = ViewBy;

    readonly engagementData: Signal<EngagementData> = computed(() => ({
        engagement: { ...this._partialEngagementData() },
        impressions: { ...this._partialImpressionsData() },
        postsCount: { ...this._postsCountData() },
    }));

    dateLabels: WritableSignal<Date[]> = signal([]);
    areAllPlatformsInError = signal(false);
    platformsErrorTooltip: WritableSignal<string | null> = signal(null);

    private _currentTotalEngagementRate: WritableSignal<number | null> = signal(null);
    private _previousTotalEngagementRate: WritableSignal<number | null> = signal(null);
    private _partialEngagementData: WritableSignal<SplittedEngagementData> = signal(DEFAULT_SPLITTED_DATA);
    private _partialImpressionsData: WritableSignal<SplittedEngagementData> = signal(DEFAULT_SPLITTED_DATA);
    private _postsCountData: WritableSignal<SplittedEngagementData> = signal(DEFAULT_SPLITTED_DATA);
    private _currentTotalImpressions: WritableSignal<number | null> = signal(null);
    private _previousTotalImpressions: WritableSignal<number | null> = signal(null);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _insightsService: InsightsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _postsService: PostsService
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByFilterSubject$.next(this.viewBy);
        }
        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.platformKeys$, this.viewByFilterSubject$])
            .pipe(
                filter(
                    ([_restaurant, dates, _platforms, actionsViewBy]) =>
                        isDateSetOrGenericPeriod(dates) && Object.values(ViewBy).includes(actionsViewBy)
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, platformKeys, actionsViewBy]: [Restaurant, DatesAndPeriod, PlatformKey[], ViewBy]) => {
                    this.viewByChange.emit(actionsViewBy);
                    const startDate = dates.startDate as Date;
                    const endDate = dates.endDate as Date;
                    const datesRange = endDate?.getTime() - startDate.getTime();
                    const previousStartDate = new Date(startDate.getTime() - datesRange);
                    const previousEndDate = new Date(endDate.getTime() - datesRange);
                    const { _id: restaurantId } = restaurant;
                    return forkJoin([
                        this._postsService.getPostsWithInsights(restaurantId, platformKeys, startDate, endDate).pipe(
                            map((res) => res.data),
                            catchError((error) => {
                                this.httpError.set(error);
                                this.hasDataChange.emit(false);
                                this.isLoading.set(false);
                                return EMPTY;
                            })
                        ),
                        this._postsService.getPostsWithInsights(restaurantId, platformKeys, previousStartDate, previousEndDate).pipe(
                            map((res) => res.data),
                            catchError((error) => {
                                this.httpError.set(error);
                                this.hasDataChange.emit(false);
                                this.isLoading.set(false);
                                return EMPTY;
                            })
                        ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: platformKeys,
                                startDate,
                                endDate,
                                metrics: [MalouMetric.FOLLOWERS],
                                aggregators: [AggregationTimeScale.BY_DAY],
                            })
                            .pipe(
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: platformKeys,
                                startDate: previousStartDate,
                                endDate: previousEndDate,
                                metrics: [MalouMetric.FOLLOWERS],
                                aggregators: [AggregationTimeScale.BY_DAY],
                            })
                            .pipe(
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        of(startDate),
                        of(endDate),
                        of(platformKeys),
                        of(actionsViewBy),
                    ]);
                }),
                filter(([postsWithInsightsByPlatform]) => !!postsWithInsightsByPlatform),
                switchMap(
                    ([
                        currentPostsWithInsightsByPlatform,
                        previousPostsWithInsightsByPlatform,
                        currentFollowersInsights,
                        previousFollowersInsights,
                        startDate,
                        endDate,
                        platforms,
                        actionsViewBy,
                    ]: [
                        PostsWithInsightsByPlatforms,
                        PostsWithInsightsByPlatforms,
                        InsightsByPlatform,
                        InsightsByPlatform,
                        Date,
                        Date,
                        PlatformKey[],
                        ViewBy,
                    ]) => {
                        const platformsInError: PlatformKey[] = this._getPlatformsInError(
                            currentPostsWithInsightsByPlatform,
                            currentFollowersInsights,
                            platforms
                        );
                        platformsInError.forEach((platform) => {
                            delete currentFollowersInsights[platform];
                        });

                        if (platforms.length === platformsInError.length) {
                            this.areAllPlatformsInError.set(true);
                            this.isLoading.set(false);
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                            return EMPTY;
                        }

                        if (platformsInError.length) {
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                        }
                        platforms = platforms.filter((e) => !platformsInError.includes(e));

                        return of([
                            currentPostsWithInsightsByPlatform,
                            previousPostsWithInsightsByPlatform,
                            currentFollowersInsights,
                            previousFollowersInsights,
                            startDate,
                            endDate,
                            actionsViewBy,
                            platforms,
                        ]);
                    }
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(
                ([
                    currentPostsWithInsightsByPlatform,
                    previousPostsWithInsightsByPlatform,
                    currentFollowersInsights,
                    previousFollowersInsights,
                    startDate,
                    endDate,
                    actionsViewBy,
                    platforms,
                ]: [
                    PostsWithInsightsByPlatforms,
                    PostsWithInsightsByPlatforms,
                    InsightsByPlatform,
                    InsightsByPlatform,
                    Date,
                    Date,
                    ViewBy,
                    PlatformKey[],
                ]) => {
                    if (
                        !currentFollowersInsights[PlatformKey.FACEBOOK]?.by_day &&
                        !currentFollowersInsights[PlatformKey.INSTAGRAM]?.by_day
                    ) {
                        this._setInsightsError(currentFollowersInsights[PlatformKey.FACEBOOK]?.message);
                        this.hasDataChange.emit(false);
                        this.isLoading.set(false);
                        return;
                    }
                    const followersCountByPlatformAndDay: Partial<Record<PlatformKey, FollowersByDayObject>> =
                        this._getFollowerCountByPlatform(currentFollowersInsights);
                    const postsInsights = this._getPostsInsights(currentPostsWithInsightsByPlatform, followersCountByPlatformAndDay);

                    // Data and Labels computing for chart
                    if (actionsViewBy === ViewBy.DAY) {
                        const days = getDaysFromCurrentRange(startDate, endDate);
                        this.dateLabels.set([...days]);
                        this._partialEngagementData.set(
                            this._mapPostsToDailyForChart({
                                posts: postsInsights,
                                key: 'engagementRate',
                                days,
                                aggregationFunction: mean,
                            })
                        );
                        this._partialImpressionsData.set(
                            this._mapPostsToDailyForChart({
                                posts: postsInsights,
                                key: 'impressions',
                                days,
                                aggregationFunction: sum,
                            })
                        );
                        this._postsCountData.set(this._mapPostsToDailyPostsCount(postsInsights, days));
                    } else if (actionsViewBy === ViewBy.WEEK) {
                        const weeks = getWeeksFromCurrentRange(startDate, endDate);
                        this.dateLabels.set(weeks.map((week) => week.start));
                        this._partialEngagementData.set(
                            this._mapPostsToWeeklyOrMonthlyForChart({
                                posts: postsInsights,
                                key: 'engagementRate',
                                timeRanges: weeks,
                                aggregationFunction: mean,
                            })
                        );
                        this._partialImpressionsData.set(
                            this._mapPostsToWeeklyOrMonthlyForChart({
                                posts: postsInsights,
                                key: 'impressions',
                                timeRanges: weeks,
                                aggregationFunction: sum,
                            })
                        );
                        this._postsCountData.set(this._mapPostsToWeeklyOrMonthlyPostsCount(postsInsights, weeks));
                    } else if (actionsViewBy === ViewBy.MONTH) {
                        const months: Month[] = getMonthsFromPeriod(startDate, endDate);
                        this.dateLabels.set(months.map((e) => e.start));
                        this._partialEngagementData.set(
                            this._mapPostsToWeeklyOrMonthlyForChart({
                                posts: postsInsights,
                                key: 'engagementRate',
                                timeRanges: months,
                                aggregationFunction: mean,
                            })
                        );
                        this._partialImpressionsData.set(
                            this._mapPostsToWeeklyOrMonthlyForChart({
                                posts: postsInsights,
                                key: 'impressions',
                                timeRanges: months,
                                aggregationFunction: sum,
                            })
                        );
                        this._postsCountData.set(this._mapPostsToWeeklyOrMonthlyPostsCount(postsInsights, months));
                    }

                    // kpi data
                    this._currentTotalEngagementRate.set(this._computeTotalEngagementRate(postsInsights, platforms));
                    this._currentTotalImpressions.set(this._computeTotalImpressions(postsInsights));

                    if (previousPostsWithInsightsByPlatform && previousFollowersInsights) {
                        const previousFollowersCountByPlatformAndDay: Partial<Record<PlatformKey, FollowersByDayObject>> =
                            this._getFollowerCountByPlatform(previousFollowersInsights);
                        const previousPostsInsights = this._getPostsInsights(
                            previousPostsWithInsightsByPlatform,
                            previousFollowersCountByPlatformAndDay
                        );
                        this._previousTotalEngagementRate.set(this._computeTotalEngagementRate(previousPostsInsights, platforms));
                        this._previousTotalImpressions.set(this._computeTotalImpressions(previousPostsInsights));
                    }

                    this.isLoading.set(false);
                }
            );
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    private _getPostsInsights(
        postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms,
        followersCountByPlatformAndDay: Partial<Record<PlatformKey, FollowersByDayObject>>
    ): PostWithInsights[] {
        return postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform)
                    .map(([key, value]) =>
                        (value.data ?? []).map(
                            (post) =>
                                new PostWithInsights({
                                    ...post,
                                    key,
                                    nbFollowers: this._getFollowerCountByDate(
                                        new Date(post.createdAt),
                                        followersCountByPlatformAndDay[key]
                                    ),
                                })
                        )
                    )
                    .flat()
            )
            .flat();
    }

    private _getFollowerCountByDate(date: Date, followersByDay: FollowersByDayObject): number | null {
        const formattedTargetDate = DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        const possibleDates = Object.keys(followersByDay).map((d) => new Date(d));
        const closestDate = getClosestValueFromDate(formattedTargetDate, possibleDates);
        return closestDate ? followersByDay[closestDate.toISOString()] : null;
    }

    private _getFollowerCountByPlatform(currentFollowersInsights: InsightsByPlatform): Partial<Record<PlatformKey, FollowersByDayObject>> {
        return Object.entries(currentFollowersInsights).reduce((acc, cur) => {
            const followersByDay = cur[1][AggregationTimeScale.BY_DAY]?.[MalouMetric.FOLLOWERS];
            const followersByDayObject = {};
            followersByDay?.forEach((insight) => (followersByDayObject[insight.date] = insight.value));
            return {
                ...acc,
                [cur[0]]: followersByDayObject,
            };
        }, {});
    }

    private _getPlatformsInError(
        postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms,
        currentFollowersInsights: InsightsByPlatform,
        filteredPlatforms: PlatformKey[]
    ): PlatformKey[] {
        const postsWithInsightsPlatformsError = postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform).map(([key, value]) =>
                    value.error && filteredPlatforms.includes(key as PlatformKey) ? (key as PlatformKey) : null
                )
            )
            .flat()
            .filter(Boolean);

        const currentFollowersPlatformsError = filteredPlatforms.filter((platform) => currentFollowersInsights[platform]?.error);

        return compact(uniq([...postsWithInsightsPlatformsError, ...currentFollowersPlatformsError]));
    }

    private _setInsightsError(message?: string): void {
        this.insightsError = this._translate.instant(getInsightsErrorText(message), {
            platformName: this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key'),
        });
    }

    private _computeTotalEngagementRate(posts: PostWithInsights[], platforms: PlatformKey[]): number {
        const engagementRateByPlatforms: number[] = [];
        // Loop all platforms that are not in error, in posts we could have posts from platforms that are in error
        for (const platform of platforms) {
            const platformPosts = posts.filter((post) => post.key === platform);
            engagementRateByPlatforms.push(...platformPosts.map((post) => post.engagementRate).filter(isNotNil));
        }
        return mean(engagementRateByPlatforms);
    }

    private _computeTotalImpressions(posts: PostWithInsights[]): number {
        const postsImpressions = sum(posts.filter((post) => post.postType !== PostType.REEL).map((post) => post.impressions));
        const reelsImpressions = sum(posts.filter((post) => post.postType === PostType.REEL).map((post) => post.plays));
        return postsImpressions + reelsImpressions;
    }

    private _mapPostsToDailyForChart({
        posts = [],
        key = 'impressions',
        days,
        aggregationFunction = sum,
    }: {
        posts: PostWithInsights[];
        key: keyof PostWithInsights;
        days: Date[];
        aggregationFunction: Function;
    }): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        const reelKey = key === 'impressions' ? 'plays' : 'engagementRate';

        for (const day of days) {
            const facebookPosts =
                posts
                    .filter((post) => post.key === PlatformKey.FACEBOOK && isSameDay(new Date(post.createdAt), day))
                    .map((post) => post[key]) || [];

            const instagramPosts = posts
                .filter(
                    (post) =>
                        post.key === PlatformKey.INSTAGRAM && isSameDay(new Date(post.createdAt), day) && post.postType !== PostType.REEL
                )
                .map((post) => post[key]);

            const instagramReels = posts
                .filter(
                    (post) =>
                        post.key === PlatformKey.INSTAGRAM && isSameDay(new Date(post.createdAt), day) && post.postType === PostType.REEL
                )
                .map((post) => post[reelKey]);

            const facebookAggregated = aggregationFunction(facebookPosts);
            chartData.facebookData?.push(isFinite(facebookAggregated) ? facebookAggregated : 0);

            const instagramAggregated = aggregationFunction([...instagramPosts, ...instagramReels]);
            chartData.instagramData?.push(isFinite(instagramAggregated) ? instagramAggregated : 0);

            const total = aggregationFunction([...facebookPosts, ...instagramPosts, ...instagramReels]);
            chartData.total?.push(isFinite(total) ? total : 0);
        }

        return chartData;
    }

    private _mapPostsToWeeklyOrMonthlyForChart({
        posts = [],
        key = 'impressions',
        timeRanges,
        aggregationFunction = sum,
    }: {
        posts: PostWithInsights[];
        key: keyof PostWithInsights;
        timeRanges: WeekRange[] | Month[];
        aggregationFunction: Function;
    }): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        const reelKey = key === 'impressions' ? 'plays' : 'engagementRate';

        for (const timeRange of timeRanges) {
            const facebookPosts =
                posts
                    .filter((post) => post.key === PlatformKey.FACEBOOK && isInDayList(new Date(post.createdAt), timeRange.days))
                    .map((post) => post[key]) || [];

            const instagramPosts = posts
                .filter(
                    (post) =>
                        post.key === PlatformKey.INSTAGRAM &&
                        isInDayList(new Date(post.createdAt), timeRange.days) &&
                        post.postType !== PostType.REEL
                )
                .map((post) => post[key]);

            const instagramReels = posts
                .filter(
                    (post) =>
                        post.key === PlatformKey.INSTAGRAM &&
                        isInDayList(new Date(post.createdAt), timeRange.days) &&
                        post.postType === PostType.REEL
                )
                .map((post) => post[reelKey]);

            const facebookAggregated = aggregationFunction(facebookPosts);
            chartData.facebookData?.push(isFinite(facebookAggregated) ? facebookAggregated : 0);

            const instagram = aggregationFunction([...instagramPosts, ...instagramReels]);
            chartData.instagramData?.push(isFinite(instagram) ? instagram : 0);

            const total = aggregationFunction([...facebookPosts, ...instagramPosts, ...instagramReels]);
            chartData.total?.push(isFinite(total) ? total : 0);
        }

        return chartData;
    }

    private _mapPostsToDailyPostsCount(posts: PostWithInsights[], days: Date[]): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        for (const day of days) {
            const filteredFacebookPosts = posts?.filter(
                (post) => post.key === PlatformKey.FACEBOOK && isSameDay(new Date(post.createdAt), day)
            );
            chartData.facebookData?.push(filteredFacebookPosts?.length);

            const filteredInstagramPosts = posts?.filter(
                (post) => post.key === PlatformKey.INSTAGRAM && isSameDay(new Date(post.createdAt), day)
            );
            chartData.instagramData?.push(filteredInstagramPosts?.length);

            chartData.total?.push(filteredFacebookPosts?.length + filteredInstagramPosts?.length);
        }
        return chartData;
    }

    private _mapPostsToWeeklyOrMonthlyPostsCount(posts: PostWithInsights[], timeRanges: WeekRange[] | Month[]): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        for (const timeRange of timeRanges) {
            const filteredFacebookPosts = posts?.filter(
                (post) => post.key === PlatformKey.FACEBOOK && isInDayList(new Date(post.createdAt), timeRange.days)
            );
            chartData.facebookData?.push(filteredFacebookPosts?.length);

            const filteredInstagramPosts = posts?.filter(
                (post) => post.key === PlatformKey.INSTAGRAM && isInDayList(new Date(post.createdAt), timeRange.days)
            );
            chartData.instagramData?.push(filteredInstagramPosts?.length);

            chartData.total?.push(filteredFacebookPosts?.length + filteredInstagramPosts?.length);
        }
        return chartData;
    }

    private _reset(): void {
        this.httpError.set(null);
        this.insightsError.set(null);
        this.isLoading.set(true);
        this.areAllPlatformsInError.set(false);
        this.platformsErrorTooltip.set(null);
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }
}
