import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, Locale, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { ConfirmActionModalComponent } from ':core/components/notification-center/components/confirm-action-modal/confirm-action-modal.component';
import { NotificationItemComponent } from ':core/components/notification-center/components/notification-item/notification-item.component';
import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { LocalStorage } from ':core/storage/local-storage';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-special-hour-notification-item',
    templateUrl: './special-hour-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, MatButtonModule],
})
export class SpecialHourNotificationComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _notificationsService = inject(NotificationService);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly notification = computed(() => this.initialNotification() as SpecialHourNotification);
    readonly isUserActionCompleted = computed(() => this.notification().data.event.action !== SpecialHourNotificationAction.NOTHING);
    readonly specialHourNotificationActionText = computed(() => {
        switch (this.notification().data.event.action) {
            case SpecialHourNotificationAction.CLOSED:
                return 'notification-center.special-hour-notification-item.button.closed';
            case SpecialHourNotificationAction.NO_CHANGE:
                return 'notification-center.special-hour-notification-item.button.open';
            default:
                return '';
        }
    });
    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
    readonly getNotificationEmoji = computed(() => this.notification().data.event.emoji);
    readonly getNotificationEventName = computed(
        () => this.notification().data.event.name[LocalStorage.getLang()] ?? this.notification().data.event.name[Locale.EN]
    );

    onNotificationClick(): void {
        this.markAsRead();
        this.notificationCenterContext.close();
    }

    redirectToInformationPage(): void {
        this._router.navigate([`/restaurants/${this.notification().data.restaurantIds[0]}/seo/informations`]);
    }

    onActionClick(isClosed: boolean): void {
        this._customDialogService
            .open(ConfirmActionModalComponent, {
                width: '550px',
                disableClose: false,
                height: 'auto',
                data: { notificationType: this.notification().type },
            })
            .afterClosed()
            .subscribe((isConfirmed) => {
                if (isConfirmed === true) {
                    this.onActionConfirmed(isClosed);
                }
            });
    }

    onActionConfirmed(isClosed: boolean): void {
        // Sync with Mazigh
        if (isClosed) {
            throw new Error('Close Method not implemented. will be done later!');
        }
        throw new Error('Open Method not implemented. will be done later!');

        this._notificationsService
            .trackNotificationAction$({
                heapEventName: isClosed
                    ? HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSED_ACTION
                    : HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_UPDATE_HOURS_ACTION,
                notificationId: this.notification().id,
                properties: {
                    eventName: this.notification().data.event.name.fr ?? '',
                },
            })
            .subscribe();
    }
}
