import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { errorReplacer, isNotNil, KeywordRanking, RestaurantRankingFormatWithScore } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { KeywordEvolutionMiniComponent } from ':modules/keywords/keyword-evolution-mini/keyword-evolution-mini.component';
import { KeywordEvolutionComponent } from ':modules/keywords/keyword-evolution/keyword-evolution.component';
import { SortBy, SortOption } from ':modules/keywords/keywords-list/keywords-list.component';
import {
    RankingPosition,
    RankingPositionOutOf,
    RankingTableDataRowWithStats,
} from ':modules/keywords/keywords-list/keywords-list.component.interface';
import { RankingsCompetitorsListComponent } from ':modules/keywords/rankings-competitors-list/rankings-competitors-list.component';
import { UpdateKeywordModalComponent } from ':modules/keywords/update-keyword-modal/update-keyword-modal.component';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { groupBy as customGroupBy, isSameDay } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { DatesAndPeriod, GeoSample, Keyword, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import * as StatisticsActions from '../../store/statistics.actions';
import * as StatisticsSelector from '../../store/statistics.selectors';

interface KeywordsInfos {
    count: {
        current: number;
        currentDetails: {
            posts: number;
            reviews: number;
        };
        diff: number;
    };
    average: {
        current: number;
        diff: number;
    };
    top20: {
        current: number;
        diff: number;
    };
}

/**
 * ⚠️ we are experimenting a new version of this component: see statistics-seo-keywords-v3.component.ts
 */
@Component({
    selector: 'app-statistics-seo-keywords',
    templateUrl: './statistics-seo-keywords.component.html',
    styleUrls: ['./statistics-seo-keywords.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        LazyLoadImageModule,
        TranslateModule,
        KeywordEvolutionMiniComponent,
        KeywordsPopularityComponent,
        MalouSpinnerComponent,
        NumberEvolutionComponent,
        SkeletonComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        EmojiPathResolverPipe,
        FlagPathResolverPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [EmojiPathResolverPipe],
})
export class StatisticsSeoKeywordsComponent implements OnInit {
    @Input() isCompetitorsColumnShown = true;
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantKeywordId');

    restaurant: Restaurant;
    keywordsInfos: KeywordsInfos;
    keywords: Keyword[];
    geoSamples: GeoSample[];

    isMalouAdmin: boolean;

    dataSource: MatTableDataSource<RankingTableDataRowWithStats>;
    selectedSortOption: SortOption;
    sortDirection = -1;
    sortOptions: SortOption[] = [];

    refreshStarted = false;
    httpError: any;
    isLoading = signal(true);

    endDate: WritableSignal<Date | null> = signal(null);

    doesPeriodEndToday: Signal<Boolean> = computed(() => {
        const endDate = this.endDate();
        return endDate ? isSameDay(endDate, new Date()) : false;
    });

    readonly userRole$ = this._store.select(selectUserInfos).pipe(
        filter(isNotNil),
        map((infos) => infos.role)
    );
    readonly isAdmin$: Observable<boolean> = this.userRole$.pipe(map((role) => role === 'admin'));

    readonly DISPLAYED_COLUMNS: string[] = ['keyword', 'language', 'volume', 'position', 'evolution', 'competitors'];

    displayedColumns: string[];
    defaultSort: Sort = { active: 'keyword', direction: 'asc' };

    private readonly _dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);
    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        private readonly _keywordsService: KeywordsService,
        private readonly _restaurantsService: RestaurantsService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _emojiPathResolverPipe: EmojiPathResolverPipe
    ) {
        this.dataSource = new MatTableDataSource<RankingTableDataRowWithStats>();
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit(): void {
        this.displayedColumns = [
            'keyword',
            'language',
            'volume',
            'position',
            'evolution',
            ...(this.isCompetitorsColumnShown ? ['competitors'] : []),
        ];
        this.isAdmin$.subscribe((isAdmin) => (this.isMalouAdmin = isAdmin));
        this.sortOptions = [
            { value: SortBy.Keywords, text: this._translate.instant('keywords.keywords') },
            { value: SortBy.Language, text: this._translate.instant('keywords.validation.lang') },
            {
                value: SortBy.SearchVolume,
                text: this._translate.instant('keywords.popularity'),
            },
            { value: SortBy.Ranking, text: this._translate.instant('keywords.maps_position') },
        ];
        this.selectedSortOption = this.sortOptions[0];
        combineLatest([this._restaurantsService.restaurantSelected$, this._dates$])
            .pipe(
                filter(([_restaurant, dates]) => !!dates.startDate && !!dates.endDate),
                map(([restaurant, dates]) => [restaurant, dates.startDate, dates.endDate]),
                tap(() => this._reset()),
                debounceTime(500),
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) => {
                    const { _id: restaurantId } = restaurant;
                    this.endDate.set(endDate);
                    return forkJoin([
                        // currentCountAndAverage
                        this._keywordsService
                            .getRestaurantKeywordsCountAndAverageScore(restaurantId, startDate, endDate)
                            .pipe(map((res) => res.data)),

                        // previousCountAndAverage
                        this._keywordsService
                            .getRestaurantKeywordsCountAndAverageScore(restaurantId, startDate, endDate, true)
                            .pipe(map((res) => res.data)),

                        // currentSamples
                        this._keywordsService.getRankingsByRestaurantId(restaurantId, startDate, endDate).pipe(map((res) => res.data)),

                        // previousSamples
                        this._keywordsService
                            .getRankingsByRestaurantId(restaurantId, startDate, endDate, true)
                            .pipe(map((res) => res.data)),

                        // keywords
                        this._keywordsService.getKeywordsByRestaurantId(restaurantId).pipe(map((res) => res.data)),

                        // restaurant
                        of(restaurant),
                    ]).pipe(
                        catchError((error) => {
                            this.httpError = error;
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return EMPTY;
                        })
                    );
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([currentCountAndAverage, previousCountAndAverage, currentSamples, previousSamples, keywords, restaurant]) => {
                this._store.dispatch(StatisticsActions.editKeywordsRawData({ keywords, samples: currentSamples }));
                this.keywords = keywords;
                this.restaurant = restaurant;
                this.geoSamples = currentSamples;
                this.dataSource.data = this._mapKeywordsToRankingTableDataRows(this.geoSamples, this.keywords);
                this.dataSource._updateChangeSubscription();
                const currentTop20 = this._getTop20(currentSamples, restaurant);
                const previousTop20 = this._getTop20(previousSamples, restaurant);
                this.keywordsInfos = this.getKeywordsInfo(currentCountAndAverage, previousCountAndAverage, currentTop20, previousTop20);
                this.isLoading.set(false);
            });

        if (this.tableSortOptions) {
            this.defaultSort = this.tableSortOptions;
        }
    }

    onSortChange(sort: Sort): void {
        if (sort.direction?.length) {
            this.tableSortOptionsChange.emit(sort);
        }
    }

    getKeywordsInfo(
        currentCountAndAverage: { average: number; count: { posts: number; reviews: number } },
        previousCountAndAverage: { average: number; count: { posts: number; reviews: number } },
        currentTop20: number,
        previousTop20: number
    ): KeywordsInfos {
        return {
            count: {
                current: Object.values(currentCountAndAverage?.count || {}).reduce((a, b) => a + b, 0),
                currentDetails: currentCountAndAverage?.count,
                diff: this._getEvolution(
                    Object.values(currentCountAndAverage?.count || {}).reduce((a, b) => a + b, 0),
                    Object.values(previousCountAndAverage?.count || {}).reduce((a, b) => a + b, 0)
                ),
            },
            average: {
                current: currentCountAndAverage?.average,
                diff: this._getEvolution(currentCountAndAverage?.average, previousCountAndAverage?.average),
            },
            top20: {
                current: currentTop20,
                diff: this._getEvolution(currentTop20, previousTop20),
            },
        };
    }

    changeSortOrder(): void {
        this.sortDirection = this.sortDirection === 1 ? -1 : 1;
        this.sortKeywords();
    }

    sortKeywords(sortOption: SortOption = this.selectedSortOption): void {
        this.selectedSortOption = sortOption;

        const sortedData = this.dataSource.data.sort((a, b) => {
            const sortDirectionCoef = this.sortDirection === 1 ? 1 : -1;
            return this._sortActiveColumn(this.selectedSortOption.value, a, b, sortDirectionCoef);
        });

        this.dataSource.data = sortedData;
    }

    isErrorFixable(error?: string): boolean {
        return !!error?.match(/exceeded your rate-limit/);
    }

    getErrorDetail = (error: string): string => {
        if (!error) {
            return this._translate.instant('keywords.no_information');
        }
        switch (error) {
            case 'results_too_far':
                return this._translate.instant('keywords.results_too_far');
            default:
                return error;
        }
    };

    isRowCurrentPositionValid(row: RankingTableDataRowWithStats): boolean {
        return row.currentPosition?.rank !== Infinity;
    }

    getPrettyLang = (lang: string): string => this._translate.instant(`header.langs.${lang}`);

    getEmojiAndTextFromPosition = (position: RankingPositionOutOf): { emojiSrc: string; title: string; caption: string } => {
        switch (true) {
            case position.rank === 1:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('trophy'),
                    title: this._translate.instant('keywords.positions.first'),
                    caption: this._translate.instant('keywords.positions.first_subtitle'),
                };
            case position.rank === 2:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('second_place_medal'),
                    title: this._translate.instant('keywords.positions.second', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.second_subtitle'),
                };
            case position.rank === 3:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('third_place_medal'),
                    title: this._translate.instant('keywords.positions.third', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.third_subtitle'),
                };
            case position.rank <= 10:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('flexed_biceps'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top10_subtitle'),
                };
            case position.rank <= 15:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('victory_hand'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top15_subtitle'),
                };
            case position.rank <= 20:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('raising_hands'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top20_subtitle'),
                };

            default:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('face_in_clouds'),
                    title: `+${position.outOf}`,
                    caption:
                        position.outOf === 20
                            ? this._translate.instant('keywords.positions.other_subtitle')
                            : this._translate.instant('keywords.positions.under20_results_subtitle', { position: position.outOf }),
                };
        }
    };

    clarifyError(err: any): string {
        if (err && err.error?.msg) {
            if (err.error.msg.includes('24 hours')) {
                return this._translate.instant('keywords.wait24h');
            }
            return err.error.msg;
        }
        if (typeof err === 'string') {
            return err;
        }
        if (err?.error?.message?.match(/Place ID is no longer valid/)) {
            return this._translate.instant('keywords.dead_restaurant');
        }
        return this._translate.instant('keywords.error_unknown') + ' : ' + JSON.stringify(err, errorReplacer) + String(err);
    }

    canRefresh(keyword: RankingTableDataRowWithStats): boolean {
        const does24HoursHavePassed = DateTime.local().diff(DateTime.fromJSDate(keyword.lastRefresh), 'hours').hours > 24;
        return (does24HoursHavePassed || !keyword.lastRefresh) && !keyword.isWaiting;
    }

    refreshKeyword(keywordRow: RankingTableDataRowWithStats): void {
        if (!this.refreshStarted) {
            this.refreshStarted = true;
            keywordRow.isWaiting = true;
            this.dataSource._updateChangeSubscription();
            const restaurantKeywordId = keywordRow.restaurantKeywordId;

            this._keywordsService.refreshRankingForKeywordId(restaurantKeywordId).subscribe({
                next: (result) => {
                    this.refreshStarted = false;
                    if (result?.length) {
                        this.geoSamples = this.geoSamples.filter((sample) => sample.keyword !== keywordRow.keyword).concat(result);
                        this.dataSource.data = this._mapKeywordsToRankingTableDataRows(this.geoSamples, this.keywords);
                        this.dataSource._updateChangeSubscription();
                        this._restaurantsService.reloadSelectedRestaurant();
                    } else {
                        keywordRow.isWaiting = false;
                    }
                },
                error: (err) => {
                    this.refreshStarted = false;
                    this._toastService.openErrorToast(this.clarifyError(err));
                    keywordRow.isWaiting = false;
                },
            });
        }
    }

    openCustomVolumeModal(keyword: RankingTableDataRowWithStats): void {
        this._customDialogService
            .open(UpdateKeywordModalComponent, {
                data: {
                    keywordToUpdate: keyword,
                    keywords: this.keywords,
                    restaurantId: this.restaurant._id,
                },
                height: '275px',
            })
            .afterClosed()
            .subscribe({
                next: (newValue) => {
                    if (newValue) {
                        keyword.volume = newValue.volume;
                        keyword.popularity = newValue.popularity;
                    }
                },
            });
    }

    openKeywordEvolution(keywordText: string): void {
        this._customDialogService
            .open(KeywordEvolutionComponent, {
                height: 'unset',
                data: {
                    positions: this._getPositions(keywordText, this.geoSamples, this.restaurant.placeId),
                    keywordText,
                    restaurantId: this.restaurant._id,
                    restaurantPlaceId: this.restaurant.placeId,
                    applyFilter: (event: Event) => {
                        const filterValue = (event.target as HTMLInputElement).value;
                        this.dataSource.filter = filterValue.trim().toLowerCase();
                    },
                },
            })
            .afterClosed();
    }

    openCompetitorsList(keyword: string, ranking: RestaurantRankingFormatWithScore[], indexPosition?: number): void {
        if (!indexPosition) {
            return;
        }
        this._customDialogService
            .open(RankingsCompetitorsListComponent, {
                height: 'unset',
                data: {
                    keyword,
                    ranking,
                    indexPosition,
                },
            })
            .afterClosed();
    }

    private _sortActiveColumn(
        value: SortBy,
        a: RankingTableDataRowWithStats,
        b: RankingTableDataRowWithStats,
        sortDirectionCoef: number
    ): number {
        switch (value) {
            case SortBy.Keywords:
                return a.keyword.toLowerCase().localeCompare(b.keyword.toLowerCase()) * sortDirectionCoef;
            case SortBy.Language:
                return a.language.toLowerCase().localeCompare(b.language.toLowerCase()) * sortDirectionCoef;
            case SortBy.SearchVolume:
                return ((a.volume ?? 0) - (b.volume ?? 0)) * sortDirectionCoef;
            case SortBy.Ranking:
                const aPosition = a.currentPosition && a.currentPosition.rank <= a.currentPosition.outOf ? a.currentPosition.rank : 999;
                const bPosition = b.currentPosition && b.currentPosition.rank <= b.currentPosition.outOf ? b.currentPosition.rank : 999;
                return (aPosition - bPosition) * sortDirectionCoef;
            default:
                return 0;
        }
    }

    private _getTop20(samples: GeoSample[], restaurant: Restaurant): number {
        if (!samples || !samples.length) {
            return 0;
        }
        const { placeId } = restaurant;
        const groupedSamples = this._getLatestSamplesFromPeriod(samples);
        const positions: RankingPositionOutOf[] = compact(
            groupedSamples.map((groupedSample) => new KeywordRanking(groupedSample).getPositionByPlaceId(placeId))
        );
        const positionsOnlyNumbers: number[] = positions
            .filter((position) => position && position.rank !== Infinity)
            .map(({ rank }) => rank);
        return this._countTop(positionsOnlyNumbers, 20);
    }

    private _getLatestSamplesFromPeriod(samples: GeoSample[]): GeoSample[][] {
        const nbUniqueKeywords = customGroupBy(samples, (sample: GeoSample) => sample.keyword).length;
        const groupedSamplesByWeekYear: GeoSample[][] = customGroupBy(samples, (sample: GeoSample) =>
            [sample.year, sample.week, sample.keyword].join(' ')
        );
        groupedSamplesByWeekYear.sort((a, b) => {
            if (b[0].year - a[0].year !== 0) {
                return b[0].year - a[0].year;
            }
            return b[0].week - a[0].week;
        });
        return groupedSamplesByWeekYear.slice(0, nbUniqueKeywords);
    }

    private _countTop(positions: number[], top: number): number {
        return positions.filter((position) => position <= top).length;
    }

    private _getEvolution(now: number, before: number): number {
        if (!before) {
            return 0;
        }
        return now - before;
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.endDate.set(null);
    }

    private _getPositions(keywordText: string, geoSamples: GeoSample[], placeId?: string): RankingPosition[] {
        const geoSamplesForKeyword = geoSamples.filter((geoSample) => geoSample.keyword === keywordText);
        const geoSamplesGroupedByKeywordWeekYear: Record<string, GeoSample[]> = groupBy(
            geoSamplesForKeyword,
            (geoSample: GeoSample): string => `${geoSample.keyword}-${geoSample.week}-${geoSample.year}`
        );

        return Object.values(geoSamplesGroupedByKeywordWeekYear).map((geoSamplesGrouped: GeoSample[]) => {
            const keywordRanking = new KeywordRanking(geoSamplesGrouped);
            return {
                position: keywordRanking.getPositionByPlaceId(placeId),
                createdAt: new Date(geoSamplesGrouped[0].createdAt),
            };
        });
    }

    private _mapKeywordsToRankingTableDataRows(samples: GeoSample[], keywords: Keyword[]): RankingTableDataRowWithStats[] {
        const isLoading = samples.length === 0;
        const keywordsSelected: Keyword[] = keywords.filter((k) => k.selected);
        const keywordsSelectedText: string[] = keywordsSelected.map((k) => k.text);
        const currentFilteredSamples = GeoSample.getRecentAndMostCompletePossibleSamples(
            samples.filter((sample) => keywordsSelectedText.includes(sample.keyword)),
            this.endDate()
        );
        const rankingTableDataRows = keywordsSelected.map((keyword): RankingTableDataRowWithStats => {
            const currentKeywordSamples = currentFilteredSamples.filter((sample) => sample.keyword === keyword.text);
            const currentRanking = new KeywordRanking(currentKeywordSamples);
            return {
                keywordId: keyword.keywordId,
                restaurantKeywordId: keyword.restaurantKeywordId,
                language: keyword.language,
                keyword: keyword.text,
                volumeFromAPI: keyword.volume,
                volume: keyword.getNotNullVolume(),
                currentPosition: isLoading ? undefined : currentRanking.getPositionByPlaceId(this.restaurant.placeId) || undefined,
                isLoading,
                ranking: [...currentRanking.getRestaurants()],
                lastRefresh: keyword.lastRefresh,
                isWaiting: false,
                error: currentKeywordSamples.every((cfs) => cfs.error),
                errorData: currentKeywordSamples.find((cfs) => cfs.error)?.errorData || '',
                shouldRefetchVolume: keyword.shouldRefetchVolume(),
                positions: this._getPositions(keyword.text, samples, this.restaurant.placeId),
                popularity: keyword.getPopularity(keywords),
            };
        });

        return rankingTableDataRows;
    }
}
