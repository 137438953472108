import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { delay, distinctUntilChanged, filter, forkJoin, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import {
    isNotNil,
    isSameDay,
    NotificationChannel,
    NotificationType,
    PERIOD_FOR_WEB_POSTS_SUGGESTION,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { Notification } from '../../models/notification.model';
import { SpecialHourNotification } from '../../models/special-hour-notification.model';
import { NotificationService } from '../../services/notifications.service';
import { PostSuggestionPopinComponent } from './post-suggestion-popin/post-suggestion-popin.component';
import { SpecialHourPopinComponent } from './special-hour-popin/special-hour-popin.component';

@Component({
    selector: 'app-notification-popins',
    template: '<div></div>',
    standalone: true,
    imports: [PostSuggestionPopinComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class NotificationPopinsComponent implements OnInit, KillSubscriptions {
    readonly notificationPopinByType = {
        [NotificationType.POST_SUGGESTION]: PostSuggestionPopinComponent,
        [NotificationType.SPECIAL_HOUR]: SpecialHourPopinComponent,
    };
    readonly notificationsTypesSortedByPriority = [NotificationType.SPECIAL_HOUR, NotificationType.POST_SUGGESTION];

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    private readonly _notificationService = inject(NotificationService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _postsService = inject(PostsService);

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                distinctUntilChanged((a, b) => a._id === b._id),
                switchMap((restaurant) =>
                    forkJoin([
                        this._notificationService.getActiveNotifications({
                            notificationTypes: this.notificationsTypesSortedByPriority,
                            channel: NotificationChannel.WEB,
                            restaurantIds: [restaurant._id],
                        }),
                        of(restaurant),
                    ])
                ),
                filter(([notifications, _restaurant]: [Notification[], Restaurant]) => notifications.length > 0),
                switchMap(([notifications, restaurant]: [Notification[], Restaurant]) => {
                    const sortedNotifications = this._sortNotificationsByPriority(notifications);
                    const firstNotification = sortedNotifications[0];

                    return forkJoin([this._shouldOpenPopin$(firstNotification, restaurant), of(firstNotification), of(restaurant)]);
                }),
                delay(TimeInMilliseconds.SECOND * 10),
                switchMap(([shouldOpenPopin, notification, restaurant]: [boolean, Notification, Restaurant]) =>
                    shouldOpenPopin ? this._openNotificationPopin$(notification, restaurant) : of(null)
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe();
    }

    private _openNotificationPopin$(notification: Notification, restaurant: Restaurant): Observable<any> {
        const popinComponent = this.notificationPopinByType[notification.type];
        if (!popinComponent) {
            return of(null);
        }
        return this._customDialogService
            .open(popinComponent, {
                width: '650px',
                height: 'auto',
                data: {
                    notification,
                    restaurant,
                },
                aggressivePopinE2ETests: true,
            })
            .afterClosed()
            .pipe(
                switchMap((_res) =>
                    this._notificationService.updateNotifications({
                        notificationIds: [notification.id],
                        update: {
                            readAt: new Date(),
                        },
                    })
                )
            );
    }

    private _sortNotificationsByPriority(notifications: Notification[]): Notification[] {
        return notifications
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .sort((a, b) => {
                const aIndex = this.notificationsTypesSortedByPriority.indexOf(a.type);
                const bIndex = this.notificationsTypesSortedByPriority.indexOf(b.type);
                return aIndex - bIndex;
            });
    }

    private _shouldOpenPopin$(notification: Notification, restaurant: Restaurant): Observable<boolean> {
        if (!notification) {
            return of(false);
        }
        switch (notification.type) {
            case NotificationType.POST_SUGGESTION:
                return this._shouldOpenPostSuggestionPopin$(restaurant._id);
            case NotificationType.SPECIAL_HOUR:
                return this._shouldOpenSpecialHourPopin$(notification as SpecialHourNotification, restaurant);
            default:
                return of(false);
        }
    }

    private _shouldOpenPostSuggestionPopin$(restaurantId: string): Observable<boolean> {
        const startDate = DateTime.now().minus({ days: PERIOD_FOR_WEB_POSTS_SUGGESTION }).toFormat('dd-MM-yyyy');
        const endDate = DateTime.now().toFormat('dd-MM-yyyy');

        return this._postsService.getPostsBetweenDates$(restaurantId, startDate, endDate).pipe(
            map((res) => {
                const { posts } = res.data;
                return !posts.some((post) => post.isPublished() && !post.isStory);
            })
        );
    }

    private _shouldOpenSpecialHourPopin$(notification: SpecialHourNotification, restaurant: Restaurant): Observable<boolean> {
        const specialHours = restaurant.specialHours;
        if (!specialHours?.length) {
            return of(true);
        }

        const eventStartDate = new Date(notification.data.event.startDate);

        const isRestaurantSpecialHoursDefined = specialHours.some((specialHour) => {
            const specialHourStartDate = DateTime.fromObject({
                day: specialHour.startDate.day,
                month: specialHour.startDate.month + 1,
                year: specialHour.startDate.year,
            }).toJSDate();
            return isSameDay(eventStartDate, specialHourStartDate);
        });

        return of(!isRestaurantSpecialHoursDefined);
    }
}
