import { z } from 'zod';

import { checkSocialNetworksDomain, SocialNetworkKey } from '@malou-io/package-utils';

import { urlValidator } from '../../utils/validators';

export const socialNetworkUrlsValidator = z
    .array(
        z.object({
            key: z.nativeEnum(SocialNetworkKey),
            url: urlValidator(),
        })
    )
    .refine((networkUrls) => networkUrls.every(({ key, url }) => checkSocialNetworksDomain(url, key)));
export type SocialNetworkUrlsDto = z.infer<typeof socialNetworkUrlsValidator>;
