import { MalouPeriod, MalouTimeScalePeriod, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

export interface UserFiltersDto {
    id: string;
    userId: string;
    aggregatedStatisticsFilters: AggregatedStatisticsFiltersDto;
    statisticsFiltersPerRestaurant: statisticsPerRestaurantFiltersDto[];
    aggregatedReviewsFilters: {
        filters: ReviewsFiltersDto;
        restaurantsIds: string[];
    };
    reviewsFiltersPerRestaurant: {
        restaurantId: string;
        filters: ReviewsFiltersDto;
    }[];
    createdAt: Date;
    updatedAt: Date;
}

export interface AggregatedStatisticsFiltersDto {
    dates: UserFiltersDatesDto;
    platforms: UserFiltersPlatformDto;
    restaurantsIds: string[];
    roiRestaurantsIds: string[];
    totemsIds: string[];
    timeScale: MalouTimeScalePeriod;
}

export interface statisticsPerRestaurantFiltersDto {
    restaurantId: string;
    filters: {
        dates: UserFiltersDatesDto;
        platforms: UserFiltersPlatformDto;
        totemsIds: string[];
        timeScale: MalouTimeScalePeriod;
    };
}

export interface ReviewsFiltersDto {
    period: MalouPeriod;
    platforms: PlatformKey[];
    text: string;
    ratings: number[];
    answered: boolean;
    notAnswered: boolean;
    pending: boolean;
    notAnswerable: boolean;
    showPrivate: boolean;
    withText: boolean;
    withoutText: boolean;
    archived: boolean;
    unarchived: boolean;
}

export interface UserFiltersPlatformDto {
    [PlatformFilterPage.BOOSTERS]: string[];
    [PlatformFilterPage.E_REPUTATION]: string[];
    [PlatformFilterPage.SEO]: string[];
    [PlatformFilterPage.SOCIAL_NETWORKS]: string[];
}

export interface UserFiltersDatesDto {
    startDate: Date | null;
    endDate: Date | null;
    period: MalouPeriod;
}
