import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { skip, switchMap, take } from 'rxjs/operators';

import { InsightsChart, InsightsTab, PlatformKey } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { AggregatedTop3PostsComponent } from ':modules/aggregated-statistics/social-networks/aggregated-top-3-posts/aggregated-top-3-posts.component';
import { AggregatedPostsInsightsTableComponent } from ':modules/aggregated-statistics/social-networks/insights/aggregated-posts-insights-table.component';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as StatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { EngagementComponent } from ':modules/statistics/social-networks/engagement/engagement.component';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { Restaurant } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-social-networks',
    templateUrl: './social-networks.component.html',
    styleUrls: ['./social-networks.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AggregatedPostsInsightsTableComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        AggregatedTop3PostsComponent,
        EngagementComponent,
    ],
})
export class SocialNetworksComponent implements OnInit {
    readonly PlatformFilterPage = PlatformFilterPage;

    isTop3PostsLoading = signal(true);
    isInsightsLoading = signal(true);

    isLoading = computed(() => this.isTop3PostsLoading() || this.isInsightsLoading());

    hasPlatformsLoadedOnce = false;

    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );

    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    readonly InsightsChart = InsightsChart;
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.AGGREGATED_PUBLICATIONS_TABLE]: {
            tableSortOptions: undefined,
        },
    });

    constructor(
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this._store
            .select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS }))
            // We skip the first emission because it is the store initialization
            .pipe(skip(1), take(1))
            .subscribe(() => {
                this.hasPlatformsLoadedOnce = true;
            });
    }

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.SOCIAL_NETWORKS }));
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectDatesFilter),
            this._store.select(StatisticsSelectors.selectRestaurantsIdsFilter),
            this._store.select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_SOCIAL_NETWORKS,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                    platforms,
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.set({
            ...this.chartOptions(),
            [chart]: {
                ...this.chartOptions()[chart],
                tableSortOptions: value,
            },
        });
    }
}
