import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { getLocalStorageKey, getSavedState } from ':core/storage/storage.metareducer';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { MalouDateFilters, MalouPeriod } from ':shared/models';

import { ReviewsDisplayMode } from '../reviews/store/reviews.reducer';
import { selectUserInfos } from '../user/store/user.selectors';

@Component({
    selector: 'app-aggregated-reputation',
    templateUrl: './aggregated-reputation.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AggregatedReputationComponent implements OnDestroy {
    constructor(private readonly _store: Store) {
        this._initAggregatedReviewsState();
    }

    _initAggregatedReviewsState(): void {
        this._store.select(selectUserInfos).subscribe((userInfos) => {
            const userId = userInfos?._id;
            const storageLocalKey = getLocalStorageKey(userId);
            const persistedState = getSavedState(storageLocalKey);
            if (persistedState?.reviews?.filters?.period === MalouPeriod.ALL) {
                this._store.dispatch(
                    ReviewsActions.editReviewsFiltersDates({
                        datesFilters: new MalouDateFilters().getFilter({ period: MalouPeriod.LAST_AND_COMING_THIRTY_DAYS }),
                    })
                );
            }
            this._store.dispatch(ReviewsActions.editReviewsFiltersCurrentView({ currentView: ReviewsDisplayMode.AGGREGATED_RESTAURANTS }));
        });
    }

    ngOnDestroy(): void {
        this._store.dispatch(ReviewsActions.resetReviewsStateExceptRestaurants());
    }
}
