import { z } from 'zod';

import { GeoSamplePlatform } from '@malou-io/package-utils';

import { booleanAsStringValidator } from '../utils/validators';

export const getRankingsForRestaurantsQueryValidator = z
    .object({
        start_date: z.string().datetime({ offset: true }),
        end_date: z.string().datetime({ offset: true }),
        restaurant_ids: z.array(z.string()).optional(),
        previous_period: booleanAsStringValidator.optional(),
    })
    .transform((data) => ({
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        previousPeriod: data.previous_period ? data.previous_period === 'true' : undefined,
        restaurant_ids: data.restaurant_ids,
    }));
export type GetRankingsForRestaurantsQueryDto = z.infer<typeof getRankingsForRestaurantsQueryValidator>;

export const getKeywordRankingsForOneRestaurantV3BodyValidator = z.object({
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    platformKey: z.nativeEnum(GeoSamplePlatform),
});
export type GetKeywordRankingsForOneRestaurantV3BodyDto = z.infer<typeof getKeywordRankingsForOneRestaurantV3BodyValidator>;

export const getKeywordRankingsForManyRestaurantsV3BodyValidator = z.object({
    restaurantIds: z.array(z.string()),
    startDate: z
        .string()
        .datetime()
        .transform((s) => new Date(s)),
    endDate: z
        .string()
        .datetime()
        .transform((s) => new Date(s)),
    platformKey: z.nativeEnum(GeoSamplePlatform),
});
export type GetKeywordRankingsForManyRestaurantsV3BodyDto = z.infer<typeof getKeywordRankingsForManyRestaurantsV3BodyValidator>;
