export function groupBy<T>(array: T[], callback: (t: T) => string): T[][] {
    const groups = {};
    array.forEach(function (o) {
        const groupKey = callback(o);
        groups[groupKey] = groups[groupKey] || [];
        groups[groupKey].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    });
}
