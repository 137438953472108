import { z } from 'zod';

import { Locale, NotificationType } from '@malou-io/package-utils';

// Define validators for each notification type

const specialHourValidator = z.object({
    emoji: z.string(),
    name: z.string(),
    startDate: z.date(),
    link: z.string().url().optional(),
});
export type SummarySpecialHourProps = z.infer<typeof specialHourValidator>;

const postSuggestionValidator = z.object({
    emoji: z.string(),
    name: z.string(),
    startDate: z.date(),
    ideas: z.string(),
    link: z.string().url().optional(),
});
export type SummaryPostSuggestionProps = z.infer<typeof postSuggestionValidator>;

const reviewValidator = z.object({
    count: z.number(),
    restaurantsCount: z.number(),
    firstRestaurantName: z.string(),
    link: z.string().url().optional(),
});
export type SummaryReviewProps = z.infer<typeof reviewValidator>;

const postErrorValidator = z.object({
    restaurantName: z.string(),
    postImgUrl: z.string().url(),
    link: z.string().url().optional(),
});
export type SummaryPostErrorProps = z.infer<typeof postErrorValidator>;

const commentValidator = z.object({
    comments: z.array(
        z.object({
            text: z.string(),
            authorDisplayName: z.string(),
            restaurantName: z.string(),
            restaurantId: z.string(),
        })
    ),
    link: z.string().url().optional(),
});
export type SummaryCommentProps = z.infer<typeof commentValidator>;

const mentionValidator = z.object({
    mentions: z.array(
        z.object({
            text: z.string(),
            authorDisplayName: z.string(),
            restaurantName: z.string(),
            restaurantId: z.string(),
        })
    ),
    link: z.string().url().optional(),
});
export type SummaryMentionProps = z.infer<typeof mentionValidator>;

const messageValidator = z.object({
    conversations: z.array(
        z.object({
            restaurantName: z.string(),
            restaurantId: z.string(),
            messages: z.array(
                z.object({
                    text: z.string(),
                    senderName: z.string(),
                })
            ),
        })
    ),
    link: z.string().url().optional(),
});
export type SummaryMessageProps = z.infer<typeof messageValidator>;

export const summaryEmailNotificationValidator = z.object({
    locale: z.nativeEnum(Locale),
    receiver: z.string(),
    allNotificationsLink: z.string().url(),
    unsubscribeLink: z.string().url(),
    trackingUrl: z.string().url(),
    notifications: z.array(
        z.object({
            type: z.nativeEnum(NotificationType),
            data: z.union([
                specialHourValidator,
                postSuggestionValidator,
                reviewValidator,
                postErrorValidator,
                commentValidator,
                mentionValidator,
                messageValidator,
            ]),
        })
    ),
});

export type SummaryEmailNotificationProps = z.infer<typeof summaryEmailNotificationValidator>;
